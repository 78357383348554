import "./index.css"
import { useNavigate } from "react-router-dom"

const NavBar = () => {
    const navigate = useNavigate()
    return (
        <div id="nav-bar-container">
            <div id="nav-name">CALVIN TZENG</div>
            <div id="nav-location">Grand Rapids, Michigan</div>

            <div id="nav-list">
                <div className="nav-list-content cursor" onClick={() => navigate("/")}>Home</div>
                <div className="nav-list-content cursor" onClick={() => navigate("/about")}>About the Maker</div>
                <div className="nav-list-content cursor"onClick={() => navigate("/instruments")}>Instruments</div>
                <div className="nav-list-content cursor"onClick={() => navigate("/contact")}>Contact</div>
            </div>
        </div>
    )
}

export default NavBar
