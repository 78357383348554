import './App.css';
import { Route, Routes } from "react-router-dom";

import backgroundImage from "./assets/violin-background-image.png"
import favicon from "./assets/favicon.ico"

//components
import NavBar from "./components/NavBar"
import Home from './components/Home';
import About from './components/About';
import Instruments from './components/Instruments';
import Contact from './components/Contact';

function App() {
  return (
    <div id="app-container">
      <img id="background-image-main" src={backgroundImage} />
      <img id="favicon" src={favicon} />
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/instruments" element={<Instruments />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
