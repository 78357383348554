import "./index.css"


const Contact = () => {
    return (
        <div id="contact-container">
            <div id="contact-content-1">Contact</div>
            <div id="contact-content-2">If you are interested in trying one of my instruments, or have any questions, please feel free to contact me via email at <a href="mailto:calvintzengviolins@gmail.com" id="email">calvintzengviolins@gmail.com</a> for availability.</div>
            <div id="contact-content-3">By Appointment Only</div>
        </div>
    )
}

export default Contact


import "./index.css"
