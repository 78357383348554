import "./index.css"

const Home = () => {
    return (
        <div id="home-container">
            <div id="home-content">Maker of fine, hand-made violins, violas, <br></br>and cellos.
                Based on the works of<br></br>
                Francesco Rugeri, Andrea Guarneri, Joseph<br></br>
                Guarnerius filius Andreas,<br></br>
                and Giuseppe Guarneri "del Gesu"</div>
        </div>
    )
}

export default Home
