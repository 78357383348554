import "./index.css"
import instrumentImage1 from "../../assets/instruments-image-1.png"
import instrumentImage2 from "../../assets/instruments-image-2.png"
import instrumentImage3 from "../../assets/instruments-image-3.png"
import instrumentImage4 from "../../assets/instruments-image-4.png"
import instrumentImage5 from "../../assets/instruments-image-5.png"
const Instruments = () => {
    return (
        <div id="instruments-container">
            <div className="instruments-container-sub">Instruments</div>
            <div id="instruments-scroll" className="instruments-container-sub">
                <div id="instruments-content">
                    Since the start of my journey as a violin maker, I have taken a special interest in the flow and style of instruments from Francesco Rugeri and the Guarneri Family.

                    <br></br>
                    <br></br>
                    Below are the current models of instrument I make. They are inspired by great old Italian makers, as well as the teachings of my mentors:
                    <br></br>
                    <span style={{fontStyle: "italic"}}>
                    - 1741 'Kochanski' Giuseppe Guarneri "del Gesu" violin
                    <br></br>
                    - 1676 'Conte Vitale' Andrea Guarneri viola
                    <br></br>
                    - 1690 Francesco Ruggieri cello
                    <br></br>
                    - 1717 Joseph Filius Guarneri cello
                    <br></br>
                    - 7/8 size Calvin Tzeng personal model cello
                    </span>

                    <br></br>
                    <br></br>
                    As a maker, I draw on my experience as a cellist, to prioritize sound, power, and comfortability of the instrument. This ensures that the musician has an instrument with a beautiful tone that can be heard in a concert hall without the toll of overworking to produce quality sound.                </div>
                <div id="instruments-images">
                    <img className="instruments-images-content-no-border" src={instrumentImage1} />
                    <img className="instruments-images-content-no-border" src={instrumentImage2} />
                    <img className="instruments-images-content" src={instrumentImage3} />
                    <img className="instruments-images-content" src={instrumentImage4} />
                    <img className="instruments-images-content" src={instrumentImage5} />
                </div>
            </div>
        </div>
    )
}

export default Instruments
