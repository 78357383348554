import "./index.css"
import test from "../../assets/violin-background-image.png"

import aboutImage1 from "../../assets/about-image-1.png"
import aboutImage2 from "../../assets/about-image-2.png"
import aboutImage3 from "../../assets/about-image-3.png"
import aboutImage4 from "../../assets/about-image-4.png"

const About = () => {
    return (
        <div id="about-container">
            <div className="about-container-sub">About</div>
            <div id="about-scroll" className="about-container-sub">
                <div id="about-content">
                    I started out as a cellist, studying under professor Marion Feldman at the Manhattan School of Music. During my time in New York City, I met Kevin Chapin, an amazing maker and friend. He sparked my interest in violin making, which led me to the beginning of my violin making journey at the Chicago School of Violin Making.

                    <br></br>
                    <br></br>
                    While I attended CSVM during the day, I studied under master maker William Whedbee at night. I later parted ways with CVSM in order to apprentice under him full time. It was then that I learned the majority of my craft.
                    <br></br>
                    <br></br>
                    Since 2020, I have worked independently in my own workshop in Grand Rapids, Michigan. I was able to hone my skills and develop a more personal style. I work closely with college and conservatory students, as well as professional orchestra players in creating their dream instrument.
                    <br></br>
                    <br></br>
                    Outside of the workshop, I enjoy playing tennis, traveling, and spending time with my wife, Lily, our kids, Zara and Rafael, and our furry shop assistants, Rengar, Guarneri, and Boba.

                </div>
                <div id="about-images">
                    <img src={aboutImage1} />
                    <img src={aboutImage2} />
                    <img src={aboutImage3} />
                    <img src={aboutImage4} />
                </div>
            </div>
        </div>
    )
}

export default About
